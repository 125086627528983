var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"py-2 position-relative d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"tile":""}},[_c('v-system-bar',{attrs:{"color":_vm.loading || !_vm.upcoming
        ? 'secondaryGray'
        : _vm.lateRuns && _vm.lateRuns.length > 0
        ? 'deepRed'
        : 'Success',"height":5,"absolute":""}}),_c('CardTitle',{attrs:{"title":_vm.title,"icon":_vm.titleIcon,"icon-color":_vm.titleIconColor}},[_c('v-row',{staticClass:"d-flex align-center",attrs:{"slot":"title","no-gutters":""},slot:"title"},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',[(_vm.loading || !_vm.upcoming)?_c('div',{staticStyle:{"display":"inline-block","height":"20px","overflow":"hidden","width":"20px"}},[_c('v-skeleton-loader',{attrs:{"type":"avatar","tile":""}})],1):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.isCloud && _vm.tab === 'late')?_c('ConcurrencyInfo',{staticClass:"text-caption position-absolute",staticStyle:{"bottom":"2px"}}):_vm._e()],1)],1),_c('div',{staticClass:"d-flex align-end flex-column",attrs:{"slot":"action"},slot:"action"},[_c('v-btn',{staticClass:"button-transition w-100 d-flex justify-end",style:({
          'border-right': ("3px solid " + (_vm.tab == 'upcoming'
              ? 'var(--v-primary-base)'
              : 'var(--v-appForeground-base)')),
          'box-sizing': 'content-box',
          'min-width': '100px'
        }),attrs:{"depressed":"","small":"","tile":"","icon":"","color":_vm.tab == 'upcoming' ? 'primary' : ''},on:{"click":function($event){_vm.tab = 'upcoming'}}},[_vm._v(" "+_vm._s(_vm.upcomingRuns && _vm.upcomingRuns.length > 0 && _vm.tab === 'late' ? ("(" + (_vm.upcomingRuns.length) + ")") : '')+" Upcoming "),_c('v-icon',{attrs:{"small":""}},[_vm._v("access_time")])],1),_c('v-btn',{staticClass:"button-transition w-100 d-flex justify-end",style:({
          'border-right': ("3px solid " + (_vm.tab == 'late'
              ? _vm.lateRuns && _vm.lateRuns.length > 0
                ? 'var(--v-deepRed-base)'
                : 'var(--v-primary-base)'
              : 'var(--v-appForeground-base)')),
          'box-sizing': 'content-box',
          'min-width': '100px'
        }),attrs:{"depressed":"","small":"","tile":"","icon":"","color":_vm.tab == 'late' ? 'primary' : ''},on:{"click":function($event){_vm.tab = 'late'}}},[(_vm.lateRuns && _vm.lateRuns.length > 0)?_c('v-icon',{attrs:{"small":"","color":"deepRed"}},[_vm._v(" warning ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.lateRuns && _vm.lateRuns.length > 0 && _vm.tab === 'upcoming' ? ("(" + (_vm.lateRuns.length) + ")") : '')+" Late "),_c('v-icon',{attrs:{"small":""}},[_vm._v("timelapse")])],1)],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.overlay),expression:"overlay"}],staticClass:"pa-0"},[_c('v-overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.overlay == 'late'),expression:"overlay == 'late'"}],attrs:{"absolute":"","z-index":"1"}},[_c('ClearLate',{attrs:{"flow-runs":_vm.lateRuns},on:{"finish":_vm.refetch}})],1),_c('v-overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.overlay == 'queue'),expression:"overlay == 'queue'"}],attrs:{"absolute":"","z-index":"1"}},[_c('WorkQueue')],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab == 'upcoming'),expression:"tab == 'upcoming'"}],staticClass:"pa-0 card-content"},[(_vm.loading || !_vm.upcoming)?_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}}):(!_vm.loading && _vm.upcomingRuns && _vm.upcomingRuns.length === 0)?_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-avatar',{staticClass:"mr-0"},[_c('v-icon',{staticClass:"green--text"},[_vm._v("check")])],1),_c('v-list-item-content',{staticClass:"my-0 py-0"},[_c('div',{staticClass:"text-subtitle-1 font-weight-light",staticStyle:{"line-height":"1.25rem"}},[_vm._v(" No upcoming runs. ")])])],1):_c('v-list',{staticClass:"card-content",attrs:{"dense":""}},_vm._l((_vm.upcomingRuns),function(item){return _c('v-lazy',{key:item.id,attrs:{"options":{
          threshold: 0.75
        },"min-height":"44","transition":"fade-transition"}},[_c('v-list-item',{attrs:{"dense":"","disabled":_vm.setToRun.includes(item.id)}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text-body-1 font-weight-regular"},[_c('router-link',{attrs:{"to":{ name: 'flow', params: { id: item.flow.id } }}},[_vm._v(" "+_vm._s(item.flow.name)+" ")]),_c('span',{staticClass:"font-weight-bold"},[_c('v-icon',{staticStyle:{"font-size":"12px"}},[_vm._v(" chevron_right ")])],1),_c('router-link',{attrs:{"to":{ name: 'flow-run', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('span',{staticClass:"text-caption mb-0 ml-n1 d-flex align-center"},[_c('LabelWarning',{attrs:{"flow":item.flow,"flow-run":item}}),_c('span',{staticClass:"ml-1"},[_vm._v(" Scheduled for "+_vm._s(_vm.formatDateTime(item.scheduled_start_time))+" ")])],1)],1),_c('v-list-item-action',{staticClass:"text-body-2",attrs:{"tile":"","min-width":"5"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"vertical-button",attrs:{"text":"","x-small":"","aria-label":"Run Now","disabled":_vm.setToRun.includes(item.id),"color":"primary"},on:{"click":function($event){return _vm.runFlowNow(item.id, item.version, item.name)}}},on),[_c('v-icon',{attrs:{"small":"","dense":"","color":"primary"}},[_vm._v(" fa-rocket")])],1)]}}],null,true)},[_c('span',[_vm._v(" Run "+_vm._s(item.name)+" now ")])])],1)],1)],1)}),1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab == 'late'),expression:"tab == 'late'"}],staticClass:"pa-0 card-content"},[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}}):(!_vm.loading && _vm.lateRuns && _vm.lateRuns.length === 0)?_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-avatar',{staticClass:"mr-0"},[_c('v-icon',{staticClass:"green--text"},[_vm._v("check")])],1),_c('v-list-item-content',{staticClass:"my-0 py-0"},[_c('div',{staticClass:"text-subtitle-1 font-weight-light",staticStyle:{"line-height":"1.25rem"}},[_vm._v(" Everything is running on schedule! ")])])],1):_c('v-list',{staticClass:"card-content",attrs:{"dense":""}},_vm._l((_vm.lateRuns),function(item){return _c('v-lazy',{key:item.id,attrs:{"options":{
          threshold: 0.75
        },"min-height":"40px","transition":"fade"}},[_c('v-list-item',{attrs:{"dense":"","three-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"text-body-1 font-weight-regular"},[_c('router-link',{attrs:{"to":{ name: 'flow', params: { id: item.flow.id } }}},[_vm._v(" "+_vm._s(item.flow.name)+" ")]),_c('span',{staticClass:"font-weight-bold"},[_c('v-icon',{staticStyle:{"font-size":"12px"}},[_vm._v(" chevron_right ")])],1),_c('router-link',{attrs:{"to":{ name: 'flow-run', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('span',{staticClass:"text-caption mb-0 ml-n1 d-flex align-center"},[_c('LabelWarning',{attrs:{"flow":item.flow,"flow-run":item}}),_c('span',{staticClass:"ml-1"},[_vm._v(" Scheduled for "+_vm._s(_vm.formatDateTime(item.scheduled_start_time))+" ")])],1),_c('v-list-item-subtitle',{staticClass:"text-caption"},[_c('DurationSpan',{attrs:{"start-time":item.scheduled_start_time}}),_vm._v(" behind schedule ")],1)],1)],1)],1)}),1)],1),_c('v-spacer'),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),(!_vm.overlay && _vm.lateRuns && _vm.lateRuns.length > 0)?_c('v-btn',{staticStyle:{"z-index":"2"},attrs:{"small":"","depressed":"","color":"primary","text":""},on:{"click":function($event){return _vm.showOverlay('late')}}},[_vm._v(" Clear late ")]):_vm._e(),(!_vm.overlay && _vm.isCloud)?_c('v-btn',{staticStyle:{"z-index":"2"},attrs:{"small":"","depressed":"","color":"primary","text":""},on:{"click":function($event){return _vm.showOverlay('queue')}}},[_vm._v(" Options ")]):_vm._e(),(_vm.overlay && !_vm.paused)?_c('v-btn',{staticStyle:{"z-index":"2"},attrs:{"small":"","depressed":"","plain":"","color":"white","width":"74","text":""},on:{"click":_vm.hideOverlay}},[_vm._v(" Close ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }