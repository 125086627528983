var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-2",attrs:{"tile":""}},[_c('CardTitle',{attrs:{"title":"Flows","icon":"pi-flow"}},[_c('div',{staticClass:"flex align-center justify-end",attrs:{"slot":"action"},slot:"action"},[_c('v-text-field',{staticClass:"flow-search",staticStyle:{"min-width":"400px"},attrs:{"value":_vm.search,"dense":"","hide-details":"","single-line":"","solo":"","flat":"","placeholder":_vm.placeholderMessage,"prepend-inner-icon":"search","autocomplete":"new-password"},on:{"input":_vm.handleTableSearchInput}})],1)]),_c('v-card-text',{staticClass:"pa-0 pl-8"},[_c('v-data-table',{staticClass:"ma-2 overflow-table",class:{ 'fixed-table': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"fixed-header":"","search":_vm.search,"mobile-breakpoint":960,"loading":_vm.loading > 0,"loading-text":"Loading your flows...","header-props":{ 'sort-icon': 'arrow_drop_up' },"items":_vm.flows,"headers":_vm.headers,"page":_vm.page,"items-per-page":_vm.limit,"must-sort":"","no-data-text":("You have no flows" + (_vm.projectId ? ' in this project!' : '!')),"server-items-length":_vm.flowCount,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'first_page',
        itemsPerPageOptions: [15, 30, 50],
        lastIcon: 'last_page',
        prevIcon: 'keyboard_arrow_left',
        nextIcon: 'keyboard_arrow_right'
      }},on:{"update:search":function($event){_vm.search=$event},"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.limit=$event},"update:items-per-page":function($event){_vm.limit=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.archived",fn:function(ref){
      var item = ref.item;
return [_c('truncate',{attrs:{"content":item.archived ? 'Archived' : 'Active'}},[_c('v-icon',{attrs:{"small":"","dark":"","color":item.archived ? 'accentPink' : 'green'}},[_vm._v(" "+_vm._s(item.archived ? 'archive' : 'pi-flow')+" ")])],1)]}},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('truncate',{attrs:{"content":item.name}},[_c('router-link',{staticClass:"link",attrs:{"data-cy":'flow-link|' +
                item.name +
                '|' +
                (item.archived ? 'archived' : 'active') +
                '-' +
                item.version,"to":{
              name: 'flow',
              params: { id: item.flow_group.id, tenant: _vm.tenant.slug }
            }}},[_c('span',[_vm._v(_vm._s(item.name))])])],1)]}},{key:"item.project.name",fn:function(ref){
            var item = ref.item;
return [_c('truncate',{attrs:{"content":item.project.name}},[_c('router-link',{staticClass:"link",attrs:{"to":{
              name: 'project',
              params: { id: item.project.id, tenant: _vm.tenant.slug }
            }}},[_c('span',[_vm._v(_vm._s(item.project.name))])])],1)]}},{key:"item.schedule",fn:function(ref){
            var item = ref.item;
return [_c('ScheduleToggle',{attrs:{"flow":item,"flow-group":item.flow_group}})]}},{key:"item.flow_runs",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"position-relative allow-overflow",staticStyle:{"height":"55px"}},[_c('LastTenRuns',{attrs:{"flow-id":item.id,"archived":item.archived}})],1)]}},{key:"item.created",fn:function(ref){
            var item = ref.item;
return [_c('truncate',{attrs:{"content":_vm.formatTime(item.created)}})]}},{key:"item.created_by.username",fn:function(ref){
            var item = ref.item;
return [_c('truncate',{attrs:{"content":item.created_by ? item.created_by.username : null}})]}},{key:"body.append",fn:function(ref){
            var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-switch',{staticClass:"archived-checkbox mr-7",attrs:{"label":"Show Archived"},model:{value:(_vm.showArchived),callback:function ($$v) {_vm.showArchived=$$v},expression:"showArchived"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }